// import http from './HttpService'
import axiosIns from '@/libs/axios'

export function getAllAds() {
  return axiosIns.get('/ads')
}

export function getAdById(id) {
  return axiosIns.get(`/ads/${id}`)
}

export function updateAd(feed) {
  return axiosIns.patch(`/ads/${feed.id}`, feed)
}
